<template>
  <div>
    <b-container fluid>
        <b-row>
            <b-col>
                <div class="title"><h1>{{data.Title}}</h1></div>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_desktop" align="center">
            <b-col>
                <video-player class="video-player-box" 
                    ref="videoPlayer"
                    :options="playerOptionsDesktop"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                    <track :src="'../../assets/video/Bisnis-Online-BGS-Fix.vtt'">
                </video-player>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_tablet" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionTablet"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_mobile" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionsMobile"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" class="bannerContent">
                <img class="for_desktop" src="../../assets/images/office-lascape.jpeg" width="100%" alt="Kantor Biogreen Science" desc="Kantor Biogreen Science">
                <img class="for_tablet" src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/office-lascape.jpeg" width="100%" alt="Kantor Biogreen Science" desc="Kantor Biogreen Science">
                <img class="for_mobile" src="../../assets/images/office-1-potrait.jpg" width="100%" alt="Kantor Biogreen Science" desc="Kantor Biogreen Science">
            </b-col>
            <b-col sm="12" class="bannerContent">
                <img class="for_desktop" src="../../assets/images/office-lascape-commingsoon.jpeg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
                <img class="for_tablet" src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/office-lascape-commingsoon.jpeg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
                <img class="for_mobile" src="../../assets/images/office-2-potrait.jpg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
            </b-col>
            <b-col sm="12" class="bannerContent">
                <img class="for_mobile" src="../../assets/images/office-3-potrait.jpg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
            </b-col>
            <b-col sm="12" class="bannerContent">
                <img class="for_mobile" src="../../assets/images/office-4-potrait.jpg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
            </b-col>
            <b-col sm="12" class="bannerContent">
                <img class="for_mobile" src="../../assets/images/office-5-potrait.jpg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
            </b-col>
            <b-col sm="12" class="bannerContent">
                <img class="for_mobile" src="../../assets/images/office-6-potrait.jpg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
            </b-col>
            <b-col sm="12" class="bannerContent">
                <img class="for_mobile" src="../../assets/images/biogreen-hq-potrait.jpg" width="100%" alt="Kantor Pusat Biogreen Science" desc="Kantor Pusat Biogreen Science">
            </b-col>
            
        </b-row>
        
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        data: {
          Title:null,

        },
        bahasa:'id',
        playerOptionsDesktop: {
          // videojs options
          
          autoplay: true,
          muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '800px',
          height: '450px',
          sources: [{
            type: "video/mp4",
            src: require("../../assets/video/Bisnis-Online-BGS-Fix.mp4")
          }],

          tracks: [{
    
            kind: "captions",
            src: require("../../assets/video/Bisnis-Online-BGS-Fix.vtt"),
            srclang: "id",
            label: "Indonesian",
            default: true
          }],   

          poster: "https://api.bisnisonlinebgs.com/video/bisnisonline.jpeg",
        },
        playerOptionTablet: {
          // videojs options
          muted: true,
          autoplay: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '700px',
          height: '420px',
          sources: [{
            type: "video/mp4",
            src: require("../../assets/video/Bisnis-Online-BGS-Fix.mp4")
          }],

          tracks: [{
    
            kind: "captions",
            src: require("../../assets/video/Bisnis-Online-BGS-Fix.vtt"),
            srclang: "id",
            label: "Indonesian",
            default: true
          }],   
          poster: "https://api.bisnisonlinebgs.com/video/bisnisonline.jpeg",
        },
        playerOptionsMobile: {
          // videojs options
          muted: true,
          autoplay: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '290px',
          height: '170px',
          sources: [{
            type: "video/mp4",
            src: require("../../assets/video/Bisnis-Online-BGS-Fix.mp4")
          }],

          tracks: [{
    
            kind: "captions",
            src: require("../../assets/video/Bisnis-Online-BGS-Fix.vtt"),
            srclang: "id",
            label: "Indonesian",
            default: true
          }],   
          poster: "https://api.bisnisonlinebgs.com/video/bisnisonline.jpeg",
          
        }
      }
    },
    mounted() {
      // console.log('this is current player instance object', this.player)

      // console.log(localStorage.getItem('bahasa'))

      this.bahasa = localStorage.getItem('bahasa')

      axios.get(`../../lang_${this.bahasa}.json`).then(response => {
        // console.log(response.data.data[0].general[0])
        this.data.Title = response.data.data[0].general[0].hqTitle
      })

      
      
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      }
    },
    methods: {

    }
  }

  
</script>


